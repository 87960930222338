<template>
  <van-empty
    description="404页面找不到了"
    image="network"
    class="empty"
    image-size="180px"
  />
</template>

<script>
export default {
  name: '404',
  data() {
    return {}
  }
}
</script>

<style scoped lang="less">
.empty {
  margin-top: 40%;
}
</style>
